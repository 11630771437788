<template>
    <div>
        <el-header>
            <div>
                <p>秦淮河游船数字化</p>
                <p>管理系统</p>
            </div>
            <div>
                <!-- <el-popover placement="right" width="400" trigger="hover">
                    <div>123123</div>
                    <i class="el-icon-s-tools" style="font-size: 26px;"></i>
                </el-popover> -->
                <el-dropdown>
                    <span class="el-dropdown-link">
                        <i class="el-icon-s-tools" style="font-size: 26px;margin-right: 10px;"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>修改密码</el-dropdown-item>
                        <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-header>
        <el-container>
            <el-aside width="200px">
                <AsideMenu />
            </el-aside>
            <el-main>
                <keep-alive :include="['TriggerList','MapConfigList']">
                    <router-view></router-view>
                </keep-alive>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import AsideMenu from "@/components/Home/AsideMenu.vue";

export default {
    components: {
        AsideMenu
    },
    data() {
        return {

        }
    },
    methods: {
        logout() {
            console.log(123);
            this.$axios({
                url: "/logout",
                method: "POST"
            }).then(res => {
                // console.log(res);
                if (res.code === 0) {
                    this.$message.success("退出登录")
                    localStorage.removeItem("hc-token")
                    this.$router.push("/login")
                }
            })
        }
    },
    mounted() {

    }
}
</script>
<style scoped>
::-webkit-scrollbar {
    /* width: 1px; //滚动条宽度 */
    display: none;
}

.el-header {
    padding: 8px 15px;
    background-color: rgb(206, 206, 206);
    color: rgb(82, 82, 82);
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.el-container {
    height: calc(100vh - 60px);
}

.el-aside {
    height: 100%;
}
</style>